<template>
  <div id="contents" class="production_complete">
    <div class="contents_head">
      <h2>{{ $getPageTitle($route.path) }}</h2>
      <div class="management_btn_group">
        <div>
          <span
            class="add_favorite"
            @click="AddFavorite"
            :class="{ on: isFavorOn }"
            >즐겨찾기 추가</span
          >
          <button
            class="btn_admin"
            @click="openModal"
            :disabled="!filterCheckPrint"
          >
            식별표 출력
          </button>
          <barcode-print-modal
            v-if="isBarcodeModalOpen"
            @onclose="closeModal"
            :printList="PrintCompleteList"
            :checkPage="checkPage"
          ></barcode-print-modal>
          <div
            class="modal_layer"
            v-if="isBarcodeModalOpen"
            @click="closeModal"
          ></div>
          <button class="btn_admin" @click="CloseThisPage()">닫기</button>
        </div>
      </div>
    </div>
    <div class="section">
      <div class="aside" v-if="$screen.width >= 1280">
        <ul>
          <li :class="{ active: tabIndex == 0 }">
            <a @click="SetOpenTabIndex(0)">생산완료&식별표 출력</a>
          </li>
        </ul>
      </div>
      <print-complete-form v-if="tabIndex == 0"></print-complete-form>
    </div>
  </div>
</template>

<script>
import PrintCompleteForm from '@/views/forms/Process/Complete/PrintCompleteForm';
import ClosePageMixin from '@/mixins/closePage';
import FavoriteMixin from '@/mixins/favorite';
import { mapGetters, mapMutations } from 'vuex';
import BarcodePrintModal from '@/layouts/components/BarcodePrintModal';
import { getExpireDate, yyyymmdd } from '@/utils/func';

export default {
  mixins: [ClosePageMixin, FavoriteMixin],
  components: {
    PrintCompleteForm,
    BarcodePrintModal,
  },
  data() {
    return {
      check_print: false,
      isBarcodeModalOpen: false,
      checkPage: '생산식별표',
    };
  },
  computed: {
    ...mapGetters({
      tabIndex: 'getOpenTabIndexFromPrintCompletePage',
      menuItems: 'getMenuItems',
      PrintCompleteListSrc: 'getPrintCompleteListFromPrintComplete',
      systemCompany: 'getSystemCompany',
      products: 'getProduct',
    }),
    filterCheckPrint() {
      let found = null;
      if (this.PrintCompleteListSrc) {
        found = this.PrintCompleteListSrc.find(x => x.check_print);
      }
      return found == undefined ? false : true;
    },
    PrintCompleteList() {
      if (this.PrintCompleteListSrc.length < 1) {
        return [];
      } else {
        return this.lodash.clonedeep(this.PrintCompleteListSrc).map(x => {
          if (this.systemCompany.expire_yn) {
            x.product_name = x.product_name + ` (${this.getExpireDate(x)}까지)`;
          }
          return x;
        });
      }
    },
  },
  methods: {
    ...mapMutations({
      SetOpenTabIndex: 'setOpenTabIndexToPrintCompletePage',
    }),
    getExpireDate(item) {
      const products = this.findInfoFromId(this.products, item.product_id);
      return yyyymmdd(
        new Date(
          getExpireDate(
            new Date(item.product_control_create_time),
            products.expire_year,
            products.expire_month,
            products.expire_day,
          ),
        ),
      );
    },
    openModal() {
      this.isBarcodeModalOpen = true;
    },
    closeModal() {
      this.isBarcodeModalOpen = false;
    },
  },
  async created() {
    if (this.$route.meta.menu_init == true) {
      this.$store.commit('InitPrintCompletePage');
      this.$route.meta.menu_init = false;
    }
    if (this.products.length < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
  },
};
</script>

<style lang="scss" scoped></style>
