<template>
  <div class="article">
    <div class="identification_table tbl_wrap">
      <div class="tbl_option">
        <div class="tbl_opt">
          <span class="option_txt">생산일자</span>
          <div>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="startDate"
                @change="setStartDate($event)"
              />
            </div>
            <p>~</p>
            <div class="input_text">
              <input
                type="date"
                placeholder="YYYY-MM-DD"
                :value="endDate"
                @change="setEndDate($event)"
              />
            </div>
          </div>
          <div class="input_search">
            <input
              type="text"
              placeholder="제품명 또는 규격 검색"
              :value="searchText"
              @input="setSearchText($event)"
            />
            <button>
              <i class="fa fa-search"></i>
            </button>
          </div>
          <button
            class="excel_btn"
            v-show="filteredPrintComplete.length > 0"
            @click="exportExcel"
          >
            엑셀로 출력
          </button>
        </div>
      </div>
      <h6>조회수 : {{ filteredPrintComplete.length }}건</h6>
      <div class="mes_tbl_wrap">
        <table class="mes_tbl">
          <colgroup>
            <col v-for="(n, index) in 6" :key="index" />
          </colgroup>
          <thead>
            <tr>
              <th>생산일자</th>
              <th>제품명{{ systemCompany.expire_yn ? ` (유통기한)` : '' }}</th>
              <th>규격</th>
              <th>수량(단위)</th>
              <th>바코드 번호</th>
              <th>
                <button class="check_btn" @click="selectAll()">
                  식별표 선택
                </button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(PrintComplete, index) in filteredPrintComplete"
              :key="PrintComplete.id"
            >
              <td>{{ PrintComplete.create_time | formatDateNoHours }}</td>
              <td class="text_left">
                {{
                  PrintComplete.product_name +
                    (systemCompany.expire_yn
                      ? ` (${getExpireDate(PrintComplete)}까지)`
                      : '')
                }}
              </td>
              <td class="text_left">{{ PrintComplete.product_standard }}</td>
              <td class="text_right">
                <b>{{ `${$makeComma(PrintComplete.quantity)}` }}</b
                >{{ `(${getUnitName(index)})` }}
              </td>
              <td>{{ `P${makeProductBarcode(PrintComplete)}` }}</td>
              <td>
                <div class="input_checkbox">
                  <label
                    class="chk_box"
                    :for="`checkbox${index}`"
                    :id="`checkbox${index}`"
                    :class="{ active: PrintComplete.check_print }"
                    @click="checkPrint(index)"
                  >
                    <i class="fa fa-check"></i>
                  </label>
                  <input type="checkbox" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import XLSX from 'xlsx';
import {
  getExpireDate,
  yyyymmdd,
  yyyy년MM월dd일HH시mm분ss초,
} from '@/utils/func';
import fetchMixin from '@/mixins/fetch';
import modalMixin from '@/mixins/modal';
import { formatDateNoHours } from '@/utils/filters';

export default {
  data() {
    return {
      showModal: false,
      product: '',
      standard: '',
      quantity: '',
      purchase: '',
      // date: '',
      barcode: '',
      app: document.querySelector('#app'),
    };
  },
  mixins: [fetchMixin, modalMixin],
  computed: {
    ...mapGetters({
      startDate: 'getStartDateFromPrintComplete',
      endDate: 'getEndDateFromPrintComplete',
      searchText: 'getSearchTextFromPrintComplete',
      PrintCompleteList: 'getPrintCompleteListFromPrintComplete',
      Product: 'getProduct',
      UnitCodes: 'getUnitCodes',
      systemCompany: 'getSystemCompany',
    }),
    filteredPrintComplete() {
      const Hangul = require('hangul-js');
      return this.PrintCompleteList.filter(
        x =>
          x.product_name.includes(this.searchText) ||
          Hangul.d(x.product_name, true)
            .map(x => x[0])
            .join('')
            .includes(this.searchText) ||
          x.product_standard.includes(this.searchText) ||
          Hangul.d(x.product_standard, true)
            .map(x => x[0])
            .join('')
            .includes(this.searchText),
      );
    },
  },
  methods: {
    getExpireDate(item) {
      const products = this.findInfoFromId(this.Product, item.product_id);
      return yyyymmdd(
        new Date(
          getExpireDate(
            new Date(item.create_time),
            products.expire_year,
            products.expire_month,
            products.expire_day,
          ),
        ),
      );
    },
    selectAll() {
      if (this.filteredPrintComplete.find(x => !x.check_print)) {
        this.filteredPrintComplete.forEach(element => {
          element.check_print = true;
        });
      } else {
        this.filteredPrintComplete.forEach(element => {
          element.check_print = !element.check_print;
        });
      }
    },
    makeProductBarcode(item) {
      if (!Object.keys(item).includes('lot')) {
        item.lot = this.lodash.clonedeep(item.barcode_num);
      }

      console.log('아이템확인', item);
      if (item.input_date != undefined) {
        const date = item.input_date.replace(/-/gi, '');
        const product_control_id = item.product_control_id;
        const fix5 = ('00000' + product_control_id).substr(-5);
        item.barcode_num = date + fix5;
        return date + fix5;
      }
    },
    checkPrint(index) {
      this.filteredPrintComplete[index].check_print = !this
        .filteredPrintComplete[index].check_print;
    },
    setStartDate(e) {
      if (e.target.value != '') {
        if (e.target.value > this.endDate) {
          this.openOneButtonModal(
            '생산일자 오류',
            `생산일자 조회기간 최후날짜(${this.endDate})<br>이후의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.startDate;
        } else {
          this.$store.commit('setStartDateToPrintComplete', e.target.value);
          this.FETCH_PRINT_COMPLETE();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setStartDateToPrintComplete', yyyymmdd(new Date()));
      }
    },
    setEndDate(e) {
      if (e.target.value != '') {
        if (e.target.value < this.startDate) {
          this.openOneButtonModal(
            '생산일자 오류',
            `생산일자 조회기간 최초날짜(${this.startDate})<br>이전의 날짜를 선택할 수 없습니다.`,
          );
          e.target.value = this.endDate;
        } else {
          this.$store.commit('setEndDateToPrintComplete', e.target.value);
          this.FETCH_PRINT_COMPLETE();
        }
      } else {
        this.openOneButtonModal('날짜 선택', '날짜를 선택해주세요.');
        this.$store.commit('setEndDateToPrintComplete', yyyymmdd(new Date()));
      }
    },
    setSearchText(e) {
      this.$store.commit('setSearchTextToPrintComplete', e.target.value);
    },
    getUnitName(index) {
      const PrintCompleteTemp = this.PrintCompleteList[index].product_id;
      const findMId = this.findInfoFromId(this.Product, PrintCompleteTemp);
      if (findMId !== '') {
        const findUId = this.findInfoFromId(
          this.UnitCodes,
          findMId.stock_unit_id,
        );
        return findUId != '' ? findUId.name : '';
      } else {
        return '';
      }
    },
    async FETCH_PRINT_COMPLETE() {
      this.showSpinner();
      await this.$store
        .dispatch('FETCH_PRINT_COMPLETE', {
          start: this.startDate,
          end: this.endDate,
        })
        .then(() => {})
        .catch(() => {
          this.openOneButtonModal(
            '로드 중 오류',
            '식별표 정보를 불러오는 중 오류가 발생했습니다.',
          );
        })
        .finally(() => {
          this.hideSpinner();
        });
    },
    exportExcel() {
      let completeList = []; // 입출고
      let date = this.startDate + '~' + this.endDate;

      const merge = [
        { s: { r: 0, c: 1 }, e: { r: 0, c: 4 } },
        { s: { r: 1, c: 1 }, e: { r: 1, c: 4 } },
      ];
      let standardInfo = [
        ['기준시간', yyyy년MM월dd일HH시mm분ss초(new Date())],
        ['조회기간', date],
        [],
        [],
        [],
      ];
      completeList = completeList.concat(standardInfo);

      completeList.push([
        '생산일자',
        '제품명',
        '규격',
        '수량(단위)',
        'LOT번호',
      ]);

      this.filteredPrintComplete.forEach((el, index) => {
        completeList.push([
          formatDateNoHours(el.create_time),
          el.product_name,
          el.product_standard,
          this.$makeComma(el.quantity) + '(' + this.getUnitName(index) + ')',
          'L' + el.lot,
        ]);
      });

      let wb = XLSX.utils.book_new();
      let complete = XLSX.utils.json_to_sheet(completeList, {
        skipHeader: true,
      });

      complete['!merges'] = merge;

      XLSX.utils.book_append_sheet(wb, complete, '제품생산일지');

      let title = '제품생산일지(' + date + ').xls';
      XLSX.writeFile(wb, title);
    },
  },
  async created() {
    if (this.startDate == null) {
      var date = new Date();
      this.$store.commit('setEndDateToPrintComplete', yyyymmdd(date));
      var date2 = new Date(date.setDate(date.getDate() - 7));
      this.$store.commit('setStartDateToPrintComplete', yyyymmdd(date2));
    }

    await this.FETCH_PRINT_COMPLETE();

    if (this.Product < 1) {
      await this.FETCH('FETCH_PRODUCT_WITH_COMPANY', '제품');
    }
    if (this.UnitCodes < 1) {
      await this.FETCH('FETCH_UNIT', '단위');
    }
  },
};
</script>

<style lang="scss" scoped></style>
